import logo from './../logo.svg';

import React from 'react'

export default function Tv() {
    return (
        <div className="App">
             <img src={logo} alt="Pick Pack Ship" style={{width:'auto', height:'10vh'}}/>
             <div className="box">
             <div className="grid-box">
                 <div className="grid-comp">
                     <p className="title" style={{flexBasis:'100%'}}>
                         Number of Orders Picked so far:
                     </p>                   
                     <p className="value">
                         55
                     </p>                   
                 </div>
                 <div className="grid-comp">
                 <p className="title" style={{flexBasis:'100%'}}>
                         Last Picked By:
                     </p>                   
                     <p className="value">
                         Gunther
                     </p>
                 </div>                 
            </div>
            <div className="grid-box-b">
                 <div className="grid-comp">
                 <p className="title" style={{flexBasis:'100%'}}>
                         Number of Orders Left:
                     </p>                   
                     <p className="value">
                         73
                     </p>
                 </div>
                 <div className="grid-comp">
                 <p className="title" style={{flexBasis:'100%'}}>
                         Total $ Amount Picked
                     </p>                   
                     <p className="value">
                         $55,983.54
                     </p>
                 </div>   
                 <div className="grid-comp">
                 <p className="title" style={{flexBasis:'100%'}}>
                         Bingo:
                     </p>                   
                     <p className="value">
                         * * * * *
                     </p>
                 </div>               
            </div>
            </div>
        </div>
    )
}
